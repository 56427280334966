import { Component, computed, inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, debounceTime, map, mergeMap } from 'rxjs';
import { ETSServiceWorkerService, EUA, LoaderService, UserActivityService } from 'ets-fe-ng-sdk';
import { AppService } from './services/app.service';
import { UtilityService } from './services/utility.service';
import { AppRouteService } from './services/route.service';
import { MenuFacadeService } from './facades/menu.facade.service';
import { AuthenticationService } from './authentication/authentication.service';
import { RemitaService } from './services/remita.service';
import { ToastNotificationsService } from '@serene-dev/toast-notifications';
import { environment } from '../environments/environment';
import { UserActivityEndpointService } from './components/user-activity/ua-extras/user-activity-endpoint.service';

@Component({
  selector: 'app-root',
  template: '',
})
export class SharedAppComponent {
  routeSub?: Subscription;
  public appRoute = inject(AppRouteService);
  protected route = inject(ActivatedRoute);
  protected router = inject(Router);
  protected swService = inject(ETSServiceWorkerService);
  protected appService = inject(AppService);
  public uS = inject(UtilityService);
  public toastNotificationsService = inject(ToastNotificationsService);
  public authF = inject(AuthenticationService);
  public menuF = inject(MenuFacadeService);
  public pageLoaderS = inject(LoaderService);
  public remitaService = inject(RemitaService);
  public userActivityEndpointService = inject(UserActivityEndpointService);
  protected readonly uaS = inject(UserActivityService);

  readonly isLoading = computed(() => this.pageLoaderS.isLoading());

  constructor() {
    this.authF.getFromLocal();
    this.authF.getFromOnline();
    this.toastNotificationsService.updateConfig({ xPosition: 'right' });
  }

  ngAfterViewInit() {
    console.log(environment);
    this.routeSub = this.appRoute.observable
      .pipe(
        map((route) => ({
          data: route.snapshot.data,
          customData: route.routeConfig?.customData,
          route,
        })),
      )
      .pipe(
        mergeMap((params) => {
          this.uS.environment.activatedRoute = params.route;
          // debugger;
          const pageTitle = params?.customData?.title || params?.data['title'];
          this.menuF.changedRoute(pageTitle);
          return this.menuF.selectBreadcrumb().pipe(map((r) => ({ bc: r, pageTitle })));
        }),
        debounceTime(100),
      )

      .subscribe(({ bc, pageTitle }) => {
        // debugger;
        environment.pageTitle = pageTitle || bc?.lastItem()?.label;
        this.appService.setTitle(environment.pageTitle);
        // debugger;
        this.uaS.add({ code: EUA.visited, pageTitle: environment.pageTitle });
        // this.uS.clearSubsToCloseOnRoute()
      });
  }

  ngOnDestroy(): void {
    this.appService.pageTitle = '';
    this.routeSub?.unsubscribe();
  }
}
